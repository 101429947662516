import axios from 'axios';
import {loginSuccess,loginRequest,loginFailure} from "./actions";
import { jwtDecode } from 'jwt-decode'

export const login = (credentials) => async (dispatch) => {
    dispatch(loginRequest());
    const user = JSON.parse(localStorage.getItem("userInfo"));
    let config = {
        headers: {
            "Content-type": "application/json",
            "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
        },
    };
    if(user){
        config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${user.token}`,
                "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
            },
        };
    }
    const loginData = {
        ...credentials,
        type: "translator"
    }
    try {

        const response = await axios.post('https://api.surrogacy-platform.com/api/auth/login', loginData, config);
        const user = jwtDecode(response.data.token);
        const existingUserInfo = localStorage.getItem("userInfo");
        if (existingUserInfo) {
            localStorage.removeItem("userInfo");
        }
        localStorage.setItem("userInfo", JSON.stringify({...user,token:response.data.token}));
        dispatch(loginSuccess({user, token: response.data.token}));
    } catch (error) {
        dispatch(loginFailure(error.response.data.message));
    }
};