import { combineReducers } from 'redux';

// Импортируем ваши редюсеры
import loginReducer from './loginReducer';
import emailReducer from './emailReducer'
import userReducer from './userReducer';
import textChatReducer from './textChatReducer';
import videoChatReducer from './videoChatReducer';

// Комбинируем редюсеры
const rootReducer = combineReducers({
  auth: loginReducer,
  email:emailReducer,
  // example: loginReducer,
  user: userReducer,
  textChat: textChatReducer,
  videoChat: videoChatReducer,
  // Добавьте другие редюсеры по мере необходимости
});

export default rootReducer;