/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect } from "react";
import { isMobile } from "react-device-detect";
import AsideMenu from "../components/AsideMenu";
import TopMenu from "../components/TopMenu";
import { useDispatch, useSelector } from "react-redux";
import { fetchChats, getUser } from "../actions/actions";
import { SocketContext } from "../context/socket";
import { useNavigate } from "react-router-dom";
import useEventObserver from "../hooks/useEventObserver";

const PageWrap = ({ children }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const socket = useContext(SocketContext);

    const user = useSelector((state) => state.user.info);
    
    useEventObserver()

    const showinvitePopup = (res) => {
        if(res){
            var answer = window.confirm(`Accept invite from ${res.name} #${res.userId}\nto ${res.donorName} #${res.donorId}`);
            if (answer) {
                socket.emit('result-request-to-translator', {userId: res.userId, responsType:'approved', donorId: res.donorId, translatorId: user.id})
                dispatch(fetchChats(res.donorId))
                    .then(data => {
                        // eslint-disable-next-line eqeqeq
                        let current = data.find(item => item.chatId == res.chatId)
                        navigate(`/videochat/${res.donorId}/${res.chatId}/${current.roomId}`)
                    })
                    .catch(error => {
                        console.error('Failed to fetch data:', error);
                    });
            } else {
                socket.emit('result-request-to-translator', {userId: res.userId, responsType:'needWait', donorId: res.donorId, translatorId: user.id})
            }
        }
    }

    useEffect(() => {
        console.log('useEffect is called');
        if(!user){
            dispatch(getUser());
        }
    }, []);

    const handleInvite = useCallback((res) => {
        console.log('user-video-request', res);
        showinvitePopup(res)
    }, [user]);

    useEffect(() => {
        if(user){
            socket.on("user-video-request", (res) => handleInvite(res));   
        }

        return () => {
            socket.off("user-video-request");
        };
    }, [socket, user]);


    return (
        <>
            {!isMobile ? <AsideMenu /> : null}
            {isMobile ? <TopMenu /> : null}
            <main>
                {children}
            </main>
        </>
    );
};

export default PageWrap;
