import { SOCKET_CONNECTION } from "../actions/types";

const initialState = {
    info: null,
    loading: false,
    error: null,
    socketConnected: false,
    balanceInvoice: null
  };
  
  const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_USER_REQUEST':
        return {
          ...state,
          loading: true,
          error: null,
        };
      case 'FETCH_USER_SUCCESS':
        return {
          ...state,
          info: action.payload,
          loading: false,
          error: null,
        };
      case 'FETCH_USER_FAILURE':
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case SOCKET_CONNECTION:
        return {
          ...state,
          socketConnected: action.payload
        };
      case 'GET_BALANCE_INVOICES_REQUEST':
        return {
          ...state,
          loading: true,
          error: null,
        };
      case 'GET_BALANCE_INVOICES_SUCCESS':
        return {
          ...state,
          balanceInvoice: action.payload,
          loading: false,
          error: null,
        };
      case 'GET_BALANCE_INVOICES_FAILURE':
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default userReducer;