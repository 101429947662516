import React from 'react';

import FormAuth from '../../components/FormAuth';

import classes from "./style/loginPage.module.scss";

const LoginPage = () => {
    return (
        <main className={classes.auth_main}>
			<section className={classes.auth}>
				<div className={classes.auth_container}>
					{/* <Link to="/" className={classes.auth_logo}>
						<img src="/cdn/images/logo-big.svg" alt="logo" />
					</Link> */}
					<FormAuth />
					{/* <div className={classes.auth_separator} />
					<span className={classes.auth_agreement}>
						By signing in, you agree
					</span>
					<div className={classes.auth_terms_block}>
						<Link to="#">Terms of Use</Link>
						<span>and</span>
						<Link href="#">Privacy Policy</Link>
					</div> */}
				</div>
			</section>
		</main>
    );
}

export default LoginPage;
