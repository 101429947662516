import {
  FETCH_CHATS_FAILURE,
  FETCH_CHATS_REQUEST,
  FETCH_CHATS_SUCCESS,
  FETCH_MESSAGES_FAILURE,
  FETCH_MESSAGES_REQUEST,
  FETCH_MESSAGES_SUCCESS,
  FETCH_TRANSLATOR_CHATS_FAILURE,
  FETCH_TRANSLATOR_CHATS_REQUEST,
  FETCH_TRANSLATOR_CHATS_SUCCESS,
  RESET_MESSAGES,
  SET_SELECTED_CHAT_LADY_ID,
} from "../actions/types";

const initialState = {
  textChatsLadiesList: [],
  chats: [],
  donorChats: [],
  messagesById: {},
  notifications: [],
  selectedLadyId: null,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRANSLATOR_CHATS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_TRANSLATOR_CHATS_SUCCESS:
      return {
        ...state,
        textChatsLadiesList: action.payload,
        loading: false,
        error: null,
      };
    case FETCH_TRANSLATOR_CHATS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_CHATS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CHATS_SUCCESS:
      // Update the state with donor-specific chats
      return {
        ...state,
        loading: false,
        donorChats: {
          ...state.donorChats,
          [action.donorId]: action.payload,
        },
        error: "",
      };
    case FETCH_CHATS_FAILURE:
      return {
        ...state,
        loading: false,
        donorChats: {
          ...state.donorChats,
          [action.donorId]: [],
        },
        error: action.payload,
      };
    case FETCH_MESSAGES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_MESSAGES_SUCCESS:
      const { chatId, messages, totalMessagesCount } = action.payload;
      const existingChat = state.messagesById[chatId];
    
      const newMessages = Array.isArray(messages) ? messages : [];
    
      const updatedMessages = existingChat
        ? [...existingChat.messages, ...newMessages]
        : newMessages;
    
      return {
        ...state,
        messagesById: {
          ...state.messagesById,
          [chatId]: {
            messages: updatedMessages,
            totalMessagesCount,
          },
        },
      };
    case SET_SELECTED_CHAT_LADY_ID:
      return {
          ...state,
          selectedLadyId: action.payload
      };
    case RESET_MESSAGES:
      return {
        ...state,
        messagesById: {
          ...state.messagesById,
          [action.payload]: {
            messages: [],
            totalMessagesCount: 0,
          },
        },
      };
    case FETCH_MESSAGES_FAILURE:
      return {
        ...state,
        loading: false,
        messagesById: {
          ...state.messages,
          [action.chatId]: [],
        },
        error: action.payload,
      };
    case "FETCH_NOTIFICATIONS_SUCCESS":
      return {
        ...state,
        notifications: action.payload,
        error: null,
      };
    case "FETCH_NOTIFICATIONS_FAILURE":
      return {
        ...state,
        notifications: [],
        error: action.payload,
      };
    case 'UPDATE_NOTIFICATION_SUCCESS':
      return {
        ...state,
        notifications: action.payload.messageNotification,
        error: null,
      };
    case 'UPDATE_NOTIFICATION_FAILURE':
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
