/* eslint-disable eqeqeq */
import React from 'react';

import classes from './style/ladiesList.module.scss'
import classNames from 'classnames';

const LadiesList = ({ladiesList, onClickLady, selectedLady}) => {
    return (
        <ul className={classes["welcome_main_avatars_bar"]}>
            {ladiesList.map((item, index) => (
                <li key={index} onClick={() => onClickLady(item)} className={classNames(selectedLady && (selectedLady == item.donorid) && classes['selected'], item.hasNotification && classes.unreaded, item.invideocall && classes['online'])}>
                    <div className={classes['avatar']}>
                        {
                            item.photo ? (
                                <img src={item.photo} alt="" />
                            ) : (
                                <p>{`${item.name.charAt(0)}`}</p>
                            )
                        }
                    </div>
                    <p className={classes['name']}>{item.name}</p>
                </li>
            ))}
        </ul>
    );
}

export default LadiesList;
