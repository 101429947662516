import React, {useEffect, useState} from 'react';
import PageWrap from '../../app/PageWrap';

import classes from './style/balancePage.module.scss'
import Button from '../../ui/Button';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { getBalanceInvoices } from '../../actions/actions';
import { convertDatetime } from '../../utils/common';
import Icon from '../../ui/Icon';

const BalancePage = () => {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.user.info);
    const balanceInvoice = useSelector((state) => state.user.balanceInvoice);
    const [withdrawalAmount, setWithdrawalAmount] = useState(0);
    const [cardNumber, setCardNumber] = useState('');
    const [withdrawalSuccess, setWithdrawalSuccess] = useState(false);
    const [amountError, setAmountError] = useState('');
    const [cardError, setCardError] = useState('');

    useEffect(() => {
        if(!user || (userInfo && !userInfo?.isLoggedIn)){
            navigate('/login')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, userInfo]);

    useEffect(() => {
        if(userInfo){
            setWithdrawalAmount(userInfo?.wallet.amount)
            dispatch(getBalanceInvoices(userInfo?.id))
        }
    }, [userInfo]);

    const formatCardNumber = (value) => {
        return value
            .replace(/\s+/g, '') // Удалить все пробелы
            .replace(/[^0-9]/gi, '') // Удалить все символы, кроме цифр
            .replace(/(.{4})/g, '$1 ') // Вставить пробелы каждые 4 цифры
            .trim(); // Удалить пробел в конце
    };

    const handleChange = (e) => {
        const formattedValue = formatCardNumber(e.target.value);
        // setCardNumber(formattedValue);
        if (formattedValue.length <= 19) {
            setCardNumber(formattedValue);
        }
        setCardError('');
    };

    const handleWithdrawal = async () => {
        try {
            if (!/^\d+$/.test(withdrawalAmount)) {
                setAmountError('Please enter only digits');
                return;
            }

            if (cardNumber.length > 19|| cardNumber.length < 19) {
                setCardError('Please enter correct card number');
                return;
            }

            const withdrawalData = {
                amount: parseFloat(withdrawalAmount),
                cardNumber: cardNumber.replace(/\s+/g, '')
            };

            let config = {
                headers: {
                    "Content-type": "application/json",
                    "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
                },
            };
            if(user){
                config = {
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${user.token}`,
                        "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
                    },
                };
            }

            await axios.post(
                'https://api.surrogacy-platform.com/api/payments/withdrawals',
                withdrawalData,
                config
            );

            // Обнулення значень полів та встановлення успішного статусу виведення
            setWithdrawalAmount('');
            setCardNumber('');
            setWithdrawalSuccess(true);
            setAmountError('');
            setCardError('');
        } catch (error) {
            console.error('Error processing withdrawal:', error);
        }
    };

    return (

        <PageWrap>
            <section className={classes['balance-page']}>
                <div className='container'>
                    <div className={classes['balance-wrapper']}>
                        <div className={classes['title']}>
                            <p>Balance</p>
                        </div>
                        <div className={classes['balance-info']}>
                            <div className={classes['currency']}>
                                <h5>Currency</h5>
                                <p>Coins</p>
                            </div>
                            <div className={classes['total']}>
                                <h5>Total</h5>
                                {
                                    userInfo &&(
                                        <p>{userInfo?.wallet.amount}</p>
                                    )
                                }
                            </div>
                        </div>
                        <div className={classes['withdrawal-money']}>
                            <div className={classes['title']}>
                                <p>Withdrawal money</p>
                            </div>
                            <form>
                                <p className={classNames(amountError && classes['error'], classes['label'])}>Ammount </p>
                                <input disabled className={classNames(amountError && classes['error'])} id="ammount" type="text" placeholder='0' value={withdrawalAmount}
                                       onChange={(e) => {
                                           setWithdrawalAmount(e.target.value);
                                           setAmountError('');
                                        }} 
                                        />
                                {amountError && <p className={classes['error-msg']}>{amountError}</p>}
                                <p className={classNames(cardError && classes['error'], classes['label'])}>Card Number </p>
                                <input
                                    className={classNames(cardError && classes.error)}
                                    id="card"
                                    type="text"
                                    placeholder="XXXX XXXX XXXX XXXX"
                                    value={cardNumber}
                                    onChange={handleChange}
                                />
                                {cardError && <p className={classes['error-msg']}>{cardError}</p>}
                                {withdrawalSuccess ? (
                                    <Button addClasses={['capitalize']} className={classes['withdrawal-btn']}>Success</Button>
                                ) : (
                                    <Button addClasses={['capitalize']} className={classes['withdrawal-btn']}

                                    onClick={handleWithdrawal}>Withdrawal</Button>
                                )}
                            </form>
                        </div>
                        <div className={classes['history']}>
                            <div className={classes['title']}>
                                <p>History</p>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th scope="col">Card</th>
                                        <th scope="col">Coins</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        balanceInvoice && balanceInvoice.map(item => (
                                            <tr key={item.id}>
                                                <td data-label="Card">{item.cardNumber}</td>
                                                <td data-label="Amount">{item.amount}</td>
                                                <td className={classes['status']} data-label="Status">{item.paid ? <Icon className={classes['success']} id="icon-plus-circle" /> : <Icon className={classes['filed']} id="icon-x-square" />}</td>
                                                <td data-label="Date">{convertDatetime(item.created_at)}</td>
                                            </tr>

                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </PageWrap>
    );
}

export default BalancePage;
