import { useContext, useRef, useEffect } from 'react';
import { SocketContext } from "../context/socket";
import ACTIONS from '../utils/utils';

export default function useWebRTC(roomID, donorId, userId) {
    const socket = useContext(SocketContext);
    const donorVideoRef = useRef(null);
    const peerConnections = useRef({});

    useEffect(() => {
        const handleNewPeer = async ({ peerID, createOffer }) => {
            if (peerID in peerConnections.current) {
                return console.warn(`Already connected to peer ${peerID}`);
            }

            peerConnections.current[peerID] = new RTCPeerConnection({
                iceServers: [{ 'urls': 'stun:stun.l.google.com:19302' }],
            });

            peerConnections.current[peerID].onicecandidate = event => {
                if (event.candidate) {
                    socket.emit(ACTIONS.RELAY_ICE, { peerID, iceCandidate: event.candidate });
                }
            };

            peerConnections.current[peerID].ontrack = ({ streams: [remoteStream] }) => {
                if (donorVideoRef.current) {
                    donorVideoRef.current.srcObject = remoteStream;
                }
            };

            if (createOffer) {
                const options = { offerToReceiveVideo: true, offerToReceiveAudio: false };
                const offer = await peerConnections.current[peerID].createOffer(options);

                await peerConnections.current[peerID].setLocalDescription(offer);

                socket.emit(ACTIONS.RELAY_SDP, { peerID, sessionDescription: offer });
            }
        };

        socket.on(ACTIONS.ADD_PEER, handleNewPeer);

        return () => {
            socket.off(ACTIONS.ADD_PEER);
        };
    }, [socket]);
    const setRemoteMedia = async ({ peerID, sessionDescription: remoteDescription }) => {
        try {
            const peerConnection = peerConnections.current[peerID];
            
            if (!peerConnection) {
                console.error(`No peer connection found for peerID: ${peerID}`);
                return;
            }
    
            // Логирование состояния
            console.log(`Signaling state before setRemoteDescription: ${peerConnection.signalingState}`);
            
            await peerConnection.setRemoteDescription(new RTCSessionDescription(remoteDescription));
    
            console.log(`Signaling state after setRemoteDescription: ${peerConnection.signalingState}`);
    
            if (remoteDescription.type === 'offer') {
                const answer = await peerConnection.createAnswer();
    
                await peerConnection.setLocalDescription(answer);
    
                socket.emit(ACTIONS.RELAY_SDP, { peerID, sessionDescription: answer });
            }
        } catch (error) {
            console.error(`Error setting remote description for peerID ${peerID}:`, error);
        }
    };

    useEffect(() => {
        socket.on(ACTIONS.SESSION_DESCRIPTION, (res) => setRemoteMedia(res));

        return () => {
            socket.off(ACTIONS.SESSION_DESCRIPTION);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);

    useEffect(() => {
        socket.on(ACTIONS.ICE_CANDIDATE, ({ peerID, iceCandidate }) => {
            peerConnections.current[peerID]?.addIceCandidate(new RTCIceCandidate(iceCandidate));
        });

        return () => {
            socket.off(ACTIONS.ICE_CANDIDATE);
        };
    }, [socket]);

    useEffect(() => {
        const handleRemovePeer = ({ peerID }) => {
            if (peerConnections.current[peerID]) {
                peerConnections.current[peerID].close();
            }

            delete peerConnections.current[peerID];
        };

        socket.on(ACTIONS.REMOVE_PEER, handleRemovePeer);

        return () => {
            socket.off(ACTIONS.REMOVE_PEER);
        };
    }, [socket]);

    useEffect(() => {
        socket.emit(ACTIONS.JOIN, { room: roomID });
        return () => {
            socket.emit(ACTIONS.LEAVE);
        };
    }, [roomID, socket]);

    return { donorVideoRef };
}
