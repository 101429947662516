import React, { useContext, useEffect, useState } from "react";

import classes from "./style/composeLetter.module.scss";
import classNames from "classnames";
import Icon from "../../ui/Icon";
import Button from "../../ui/Button";
import { SocketContext } from "../../context/socket";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchMessagesForEmail,
    sendEmail,
} from "../../actions/emailsActions";

const ComposeLetter = ({
    closeCompose,
    isReply,
    senderLetter,
}) => {
    // const [fileName, setFileName] = useState("");
    // const [file, setFile] = useState(null);
    // const user = JSON.parse(localStorage.getItem("userInfo"));
    const socket = useContext(SocketContext);
    const dispatch = useDispatch();
    const [message, setMessage] = useState("");
    const selectedLadyId = useSelector((state) => state.email.selectedLadyId);

    useEffect(() => {
        if (senderLetter) {
            socket.emit("join chat", senderLetter.id);
        }
    }, [socket, senderLetter]);

    // const handleFileChange = (event) => {
    //     const fileInput = event.target;
    //     if (fileInput.files.length > 0) {
    //         const selectedFile = fileInput.files[0];
    //         setFileName(selectedFile.name);
    //         setFile(selectedFile);
    //     } else {
    //         setFileName("");
    //         setFile(null);
    //     }
    // };

    // const handleRemoveFile = () => {
    //     setFileName("");
    //     setFile(null);
    // };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const sendMail = () => {
        const emailData = {
            conversationId: parseInt(senderLetter.id), 
            senderId: parseInt(selectedLadyId),
            message: message,
            type: "EMAIL",
        };

        dispatch(sendEmail(emailData))
            .then((info) => {
                socket.emit("new message", info);
                dispatch(fetchMessagesForEmail(1, 20, senderLetter.id));
            })
            .catch((error) => {
                console.error("Failed to send email:", error);
            });
        closeCompose();
    };

    return (
        <div
            className={classNames(
                classes["email_window"],
                classes["email_modal_window"],
                isReply ? classes["in_reply"] : ""
            )}
        >
            <div className={classes["email_window_header"]}>
                <h5>Message for {senderLetter.name}</h5>
                <div className={classes["email_window_buttons"]}>
                    <div
                        className={classNames(classes["image"], classes["new_email_close"])}
                        onClick={closeCompose}
                    >
                        <Icon className="icon-x" id="icon-x" />
                    </div>
                </div>
            </div>
            <div className={classes["email_window_body"]}>
                <textarea
                    placeholder="Type message…"
                    onChange={handleMessageChange}
                ></textarea>
            </div>
            <div className={classes["email_window_footer"]}>
                <div className={classes["email_window_left_buttons"]}>
                    <Button
                        addClasses={["button", "default", "capitalize"]}
                        onClick={sendMail}
                        className={classes["send-btn"]}
                    >
                        Send
                    </Button>
                    {/* {!fileName && (
                        <label htmlFor="input_files_new">
                            <div
                                className={classNames(classes["image"], classes["add_file"])}
                            >
                                <Icon className="icon-paperclip" id="icon-paperclip" />
                            </div>
                            <input
                                type="file"
                                className={classNames(
                                    "display-none",
                                    classes["input_files_new"]
                                )}
                                id="input_files_new"
                                onChange={handleFileChange}
                            />
                        </label>
                    )} */}
                    {/* {fileName && (
                        <div className={classes["file-name"]}>
                            <p>{fileName}</p>
                            <div
                                className={classes["remove-file"]}
                                onClick={handleRemoveFile}
                            >
                                <Icon className="icon-x" id="icon-x" />
                            </div>
                        </div>
                    )} */}
                    <div className={classes["added_file_list_new"]}></div>
                </div>
                <div className={classes["email_window_right_buttons"]}>
                    <div
                        className={classNames(classes["image"], classes["delete_message"])}
                    >
                        <Icon className="icon-bin" id="icon-bin" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComposeLetter;
