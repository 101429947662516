import {
    FETCH_EMAILS_REQUEST, FETCH_EMAILS_SUCCESS, FETCH_EMAILS_FAILURE,
    FETCH_MESSAGES_FOR_EMAIL_SUCCESS, FETCH_MESSAGES_FOR_EMAIL_FAILURE, FETCH_MESSAGES_FOR_EMAIL_REQUEST,FETCH_TRANSLATOR_EMAILS_REQUEST,
    FETCH_TRANSLATOR_EMAILS_FAILURE,FETCH_TRANSLATOR_EMAILS_SUCCESS,SEND_EMAIL_SUCCESS,SEND_EMAIL_REQUEST,SEND_EMAIL_FAILURE,SET_SELECTED_LADY_ID
} from "../actions/types";

const initialState = {
    loading: false,
    emails: [],
    messagesChain: null, // Додаємо нове поле для повідомлень
    donorsList: [], // New state for donors list
    error: null,
    singleEmail: null,
    sendedEmail:null,
    selectedLadyId: null,
};

const emailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EMAILS_REQUEST:
            return { ...state, loading: true };
        case FETCH_EMAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                emails: action.payload.reduce((acc, email) => {
                  return {
                    ...acc,
                    [email.mailsId]: email,
                  };
                }, {}),
            };
        case FETCH_EMAILS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case FETCH_MESSAGES_FOR_EMAIL_REQUEST:
            return { ...state, loading: true };
        case FETCH_MESSAGES_FOR_EMAIL_SUCCESS:
            return { ...state, loading: false, messagesChain: action.payload }; 
        case FETCH_MESSAGES_FOR_EMAIL_FAILURE:
            return { ...state, loading: false, error: action.payload }; 
        case FETCH_TRANSLATOR_EMAILS_REQUEST:
            return { ...state, loading: true };
        case FETCH_TRANSLATOR_EMAILS_SUCCESS:
            return { ...state, loading: false, donorsList: action.payload };
        case FETCH_TRANSLATOR_EMAILS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case SEND_EMAIL_REQUEST:
            return { ...state, loading: true };
        case SEND_EMAIL_SUCCESS:
            return { ...state, loading: false, sendedEMail: action.payload };
        case SEND_EMAIL_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case SET_SELECTED_LADY_ID:
            return {
                ...state,
                selectedLadyId: action.payload
            };
        default:
            return state;
    }
};

export default emailsReducer;
