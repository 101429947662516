/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";

import classes from "../style/mailboxReadLetter.module.scss";
import classNames from "classnames";
import Icon from "../../../ui/Icon";
import MailboxReply from "./MailboxReply";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchEmailsForDonor,
    fetchMessagesForEmail,
    fetchTranslatorEmails,
    updateEmailNotification,
} from "../../../actions/emailsActions";
import { convertDatetime } from "../../../utils/common";
import { socket } from "../../../context/socket";

const MailboxReadLetter = ({ mailId }) => {
    const [showReply, setShowReply] = useState(false);
    const [latestMessage, setLatestMessage] = useState(null);
    const [senderLetterInfo, setSenderLetterInfo] = useState(null);
    const navigate = useNavigate();
    const lettersList = useRef();
    const dispatch = useDispatch();
    const emails = useSelector((state) => state.email.emails);
    const messagesChain = useSelector((state) => state.email.messagesChain);
    const selectedLadyId = useSelector((state) => state.email.selectedLadyId);
    const user = useSelector((state) => state.user.info);

    useEffect(() => {
        dispatch(fetchMessagesForEmail(1, 20, mailId));
        dispatch(updateEmailNotification(parseInt(mailId)))
        dispatch(fetchTranslatorEmails())
    }, [dispatch, mailId]);

    useEffect(() => {
        if(emails){
            setSenderLetterInfo({
                id: mailId,
                name: emails[mailId]?.sender.name
            })
        }
    }, [emails]);

    useEffect(() => {
        if(messagesChain){
            setLatestMessage(messagesChain.messages[0])
        }
    }, [messagesChain]);

    useEffect(() => {
        if (lettersList && messagesChain) {
            lettersList.current.scrollTop = lettersList.current.scrollHeight;
        }
    }, [messagesChain]);

    const onShowReply = () => {
        setShowReply(!showReply);
    };

    const backToList = () => {
        navigate(-1);
        dispatch(fetchEmailsForDonor(selectedLadyId))
    };

    const handleNotification = useCallback((mes) => {
        dispatch(fetchMessagesForEmail(1, 20, mailId));
        dispatch(updateEmailNotification(parseInt(mailId)))
    }, []);

    useEffect(() => {
        if(user){
            socket.on("notification", (mes) => handleNotification(mes));
        }

        return () => {
            socket.off("notification");
        };
    }, [socket, dispatch]);

    if(!messagesChain) {
        return null
    }

    return (
        <div className={classes["email_letters_list"]}>
            <div className={classes["email_letters_list_header"]}>
                <div className={classes["email_letters_list_header_left_block"]}>
                    <div
                        className={classNames(classes["image"], classes["close_email"])}
                        onClick={backToList}
                    >
                        <Icon id="icon-arrow-left" className="icon-arrow-left" />
                    </div>
                    <span>{messagesChain.subject}</span>
                </div>
                <div className={classes["email_letters_list_header_right_block"]}>
                    <div className={classes["image"]}>
                        <Icon id="icon-starred" className="icon-starred" />
                    </div>
                </div>
            </div>
            {!showReply ? (
                <>
                    <div className={classes["email_letters_list_area"]} ref={lettersList}>
                        {messagesChain.messages &&
                            messagesChain.messages.map((mes) => {
                                return (
                                    <div className={classes["email_letters_list_letter"]}>
                                        <div
                                            className={classes["email_letters_list_letter_header"]}
                                        >
                                            <div
                                                className={
                                                    classes["email_letters_list_letter_header_personal"]
                                                }
                                            >
                                                {
                                                    mes.sender?.photo ? (
                                                        <div className={classNames(classes["avatar"], "avatar")}>
                                                            <img src={mes.sender?.photo} alt="" />
                                                        </div>
                                                    ) : (
                                                        <div className={classNames(classes["avatar"], "avatar")}>
                                                            {`${mes.sender?.name.charAt(0)}`}
                                                        </div>
                                                    )
                                                }
                                                <span>{`${mes.sender?.name}`}</span>
                                            </div>
                                            <div
                                                className={classes["email_letters_list_letter_date"]}
                                            >
                                                <span>{convertDatetime(mes.date)}</span>
                                            </div>
                                        </div>
                                        <div className={classes["email_letters_list_letter_body"]}>
                                            <p>{mes.text}</p>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                    <div className={classes["email_letters_list_footer"]}>
                        <span>Click here to</span>
                        <p className={classes["reply-button"]} onClick={onShowReply}>
                            Reply
                        </p>
                    </div>
                </>
            ) : (
                <MailboxReply
                    onShowReply={onShowReply}
                    mailId={mailId}
                    senderLetter={senderLetterInfo}
                    latestMessage={latestMessage}
                />
            )}
        </div>
    );
};

export default MailboxReadLetter;
