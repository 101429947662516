/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isMobile, isTablet } from 'react-device-detect';

import PageWrap from '../../app/PageWrap';
import VideoCallsList from './components/VideoCallsList';
import VideoCallsRoom from './components/VideoCallsRoom';

import classNames from 'classnames';
import classes from './style/videocall.module.scss';
import EmptyChat from '../../components/EmptyChat';
import LadiesList from '../../components/LadiesList';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTranslatorVideoChats } from '../../actions/videochatActions';
import { setSelectedLadyIdAction } from '../../actions/actions';
import { SocketContext } from '../../context/socket';
import { ChatState } from '../../context/chatProvider';

const Videochat = () => {
    const { donorId } = useParams();
    const chatBlockRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const socket = useContext(SocketContext);
    const [showTextChatRoom, setShowTextChatRoom] = useState(true);
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const videoChatDonorsList = useSelector(state => state.videoChat.videoChatDonorsList);
    const selectedLadyId = useSelector(state => state.videoChat.selectedLadyId);

    const {
        selectedChat,
        setSelectedChat,
        setDonor,
    } = ChatState();

    useEffect(() => {
        setSelectedChat(null)
        setDonor(null)
        return () => {
            setSelectedChat(null)
            setDonor(null)
        }
    }, []);

    useEffect(() => {
        dispatch(fetchTranslatorVideoChats());
        if (donorId) {
            dispatch(setSelectedLadyIdAction(donorId))
        }
        return () => {
            dispatch(setSelectedLadyIdAction(null))
        }
    }, [dispatch]);

    useEffect(() => {
        if(donorId){
            setDonor(videoChatDonorsList.find(donor => donor.donorId == donorId))
        }
    }, [donorId, videoChatDonorsList]);

    useEffect(() => {
        if (isTablet || isMobile) {
            setShowTextChatRoom(false)
        }
    }, []);

    useEffect(() => {
        if (selectedChat && (!isTablet || !isMobile)) {
            setShowTextChatRoom(true)
        } else (
            setShowTextChatRoom(false)
        )
    }, [selectedChat]);

    const handleDonorOnline = useCallback((res) => {
        dispatch(fetchTranslatorVideoChats());
        if(donorId == res.donorId){
            navigate('/videochat')
            dispatch(setSelectedLadyIdAction(null))
            setDonor(null)
            setSelectedChat(null)
        }
     }, [donorId]);

    useEffect(() => {
        if(user){
            socket.on("donor-start-video", (res) => handleDonorOnline(res));
            socket.on("donor-close-video", (res) => handleDonorOnline(res));
        }

        return () => {
            socket.off("donor-start-video");
            socket.off("donor-close-video");
        };
    }, [socket, user, donorId]);

    const onClickLady = (donor) => {
        navigate(`/videochat/${donor.donorId}`)
        dispatch(setSelectedLadyIdAction(donor.donorId))
        setDonor(donor)
        setSelectedChat(null)
    }

    return (
        <PageWrap>
            <section className={classes["chat"]}>
                <div className="container">
                    {
                        videoChatDonorsList && (
                            <LadiesList ladiesList={videoChatDonorsList} onClickLady={onClickLady} selectedLady={selectedLadyId} />
                        )
                    }
                    {
                        selectedLadyId ? (
                            <div className={classNames(classes["chat_block"], classes["video_chat_block"])} ref={chatBlockRef}>
                                {
                                    isTablet || isMobile ? (
                                        !showTextChatRoom ? (
                                            <VideoCallsList />
                                        ) : (
                                            <VideoCallsRoom />
                                        )
                                    ) : (
                                        <>
                                            <VideoCallsList />
                                            {
                                                !selectedChat ? (
                                                    <EmptyChat text="Let’s start Video Chat with Donors" />
                                                ) : (
                                                    <VideoCallsRoom />
                                                )
                                            }
                                        </>
                                    )
                                }
                            </div>
                        ) : (
                            <div className={classes['no-ladies']}>
                                <h2>{videoChatDonorsList.length ? "Select Donor" : "No Donors in Video Chat"}</h2>
                            </div>
                        )
                    }
                </div>
            </section>
        </PageWrap>
    );
}

export default Videochat;
