import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../ui/Button';
import Icon from '../../../ui/Icon';
import {useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import classes from "../style/formAuth.module.scss";
import{login} from "../../../actions/authActions";
import classNames from 'classnames';

const LogIn = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { user, error, loading } = useSelector((state) => state.auth);
    const [showPass, setShowPass] = useState(false);

    const showPassword = () => {
        setShowPass(!showPass);
    };

    useEffect(() => {
        if (user) {
            navigate('/textchat'); 
        }
    }, [user,navigate]);

    const onSubmit = (data) => {
        dispatch(login(data)); 
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
            <label htmlFor="email" className={classes.auth_input}>
                Your Email
                <input
                    type="text"
                    placeholder="Enter your email"
                    {...register("email", {
                        required: 'Email is required',
                        pattern: {
                            value: /^\S+@\S+$/i,
                            message: 'Invalid email address'
                        }
                    })}
                    autoComplete="off"
                />
                {errors.email && <p className={classes.error}>{errors.email.message}</p>}
            </label>
            <label htmlFor="password" className={classes.auth_input}>
                Password
                <input
                    type={showPass ? "text" : "password"}
                    placeholder="Enter your password"
                    {...register("password", { required: 'Password is required' })}
                    autoComplete="off"
                />
                <button
                    type="button"
                    className={classes['input-show-pass-btn']}
                    onClick={showPassword}
                >
                    {showPass ? (
                        <Icon id="icon-eye" className={classNames(classes['eye-open'])} />
                    ) : (
                        <Icon id="icon-eye-off" className={classNames(classes.eye)} />
                    )}
                </button>
                {errors.password && <p className={classes.error}>{errors.password.message}</p>}
            </label>
            {error && <p className={classes.error}>{error}</p>}

            <Button type="submit" addClasses={["button"]} disabled={loading}>
                {loading ? 'Logging in...' : 'Log in'}
            </Button>
        </form>
    );
}

export default LogIn;
