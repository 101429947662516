import React from "react";
import { useParams } from "react-router-dom";
import Icon from "../../../ui/Icon";
import classNames from "classnames";

import classes from "../style/mailboxLeftMenu.module.scss";

const MailboxLeftMenu = ({onChangeBox, showMenuMobile}) => {

    const {box} = useParams();

    const mailboxList = [
        { path: "inbox", icon: "inbox", label: "Inbox", count: 8, boxTitle: "inbox" },
        { path: "fav", icon: "starred", label: "Starred", boxTitle: "starred" },
    ];

    return (
        <div className={classNames(classes.email_menu_block, !showMenuMobile ? classes.email_menu_block_hide : '')}>
            <ul className={classes.email_menu}>
                {mailboxList.map(({ path, icon, label, count, boxTitle }, index) => (
                    <li key={index}>
                        <div
                            className={classNames({ [classes.active]: box === path }, classes.email_type_button)}
                            onClick={() => onChangeBox(path, boxTitle)}
                        >
                            <div className={classes.image}>
                                <Icon id={`icon-${icon}`} className={`icon-${icon}`} />
                            </div>
                            <span>{label}</span>
                            {/* {count && (
                                <div
                                    className={classNames(
                                        classes.messages_count,
                                        classes[`messages_count_${icon}`]
                                    )}
                                >
                                    {count}
                                </div>
                            )} */}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default MailboxLeftMenu;
