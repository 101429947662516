import React, { useState } from "react";
import Icon from "../../../ui/Icon";
import classNames from "classnames";

import classes from "../style/mailboxLettersList.module.scss";
import { useDispatch } from "react-redux";
import { convertDatetime } from "../../../utils/common";
import { starEmail } from "../../../actions/emailsActions";

const MailboxLettersList = ({
    emailMessages,
    showMessage,
    onShowMenuMobile,
    showMenuMobile,
}) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [starredEmails, setStarredEmails] = useState([]);

    const dispatch = useDispatch();

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };
    
    const filteredEmailMessages = Object.values(emailMessages).filter(
        (message) =>
        message.sender.userName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        message.subject.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const showMail = (mailId) => {
        showMessage(mailId)
    };

    const handleMessageInFav = async (event, mailId) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch(starEmail(mailId));
        setStarredEmails((prevFavs) => {
            const isAlreadyFav = prevFavs.includes(mailId);
            if (isAlreadyFav) {
                return prevFavs.filter((id) => id !== mailId);
            } else {
                return [...prevFavs, mailId];
            }
        });
    };

    const emailMessagesArray = Object.values(emailMessages).reverse();

    return (
        <div
            className={classNames(
                classes.email_main,
                showMenuMobile ? classes.overlay : ""
            )}
            onClick={showMenuMobile ? onShowMenuMobile : undefined}
        >
            <div className={classes.email_search}>
                <div
                    className={classNames(classes.image, classes.email_back_button)}
                    onClick={onShowMenuMobile}
                >
                    <Icon className="icon-burger" id="icon-burger" />
                </div>
                <Icon className="icon-search" id="icon-search" />
                <input
                    type="text"
                    placeholder="Search email"
                    className={classes.search_email}
                    value={searchQuery}
                    onChange={handleSearch}
                />
                <div
                    className={classNames(
                        classes.close_button_search,
                        !searchQuery ? "display-none" : ""
                    )}
                    onClick={() => setSearchQuery("")}
                >
                    <Icon className="icon-x" id="icon-x" />
                </div>
            </div>
            <div className={classes.email_list}>
                {searchQuery && filteredEmailMessages
                    ? filteredEmailMessages.map((message, index) => (
                        <div
                            key={index}
                            className={classNames(
                                classes.email_message,
                                message.unreaded ? classes.unreaded : ""
                            )}
                        >
                            <div
                                className={classes.email_info}
                                onClick={() => showMail(message.mailsId)}
                            >
                                {message.sender.photo ? (
                                    <div className={classNames(classes["avatar"], "avatar")}>
                                        <img src={message.sender.photo} alt="" />
                                    </div>
                                ) : (
                                    <div className={classNames(classes["avatar"], "avatar")}>
                                        {`${message.sender.userName.charAt(0)}`}
                                    </div>
                                )}
                                <div className={classes.email_data}>
                                    <span className={classes.email_name}>
                                        {message.sender.userName}
                                    </span>
                                    <span className={classes.email_title}>
                                        {message.subject}
                                    </span>
                                </div>
                                <div className={classes.email_additional}>
                                    {message.withAttachment && (
                                        <Icon className="icon-paperclip" id="icon-paperclip" />
                                    )}
                                    <span className={classes.email_datetime}>
                                        {convertDatetime(message.latestMessage.date)}
                                    </span>
                                </div>
                            </div>
                            <div className={classes.email_content}>
                                <div
                                    className={classes.email_star}
                                    onClick={(e) => handleMessageInFav(e, message.mailsId)}
                                >
                                    {message.isStarred ||
                                        starredEmails.includes(message.mailsId) ? (
                                        <Icon
                                            className="icon-star-checked"
                                            id="icon-star-checked"
                                        />
                                    ) : (
                                        <Icon className="icon-starred" id="icon-starred" />
                                    )}
                                </div>

                                {message.latestMessage && <p>{message.latestMessage.text}</p>}
                            </div>
                        </div>
                    ))
                    : emailMessagesArray.map((message, index) => (
                        <div
                            key={index}
                            className={classNames(
                                classes.email_message,
                                message.unreaded ? classes.unreaded : ""
                            )}
                        >
                            <div
                                className={classes.email_info}
                                onClick={() => showMail(message.mailsId)}
                            >
                                {message.sender.photo ? (
                                    <div className={classNames(classes["avatar"], "avatar")}>
                                        <img src={message.sender.photo} alt="" />
                                    </div>
                                ) : (
                                    <div className={classNames(classes["avatar"], "avatar")}>
                                        {`${message.sender.userName.charAt(0)}`}
                                    </div>
                                )}
                                <div className={classes.email_data}>
                                    <span className={classes.email_name}>
                                        {message.sender.userName}
                                    </span>
                                    <span className={classes.email_title}>
                                        {message.subject}
                                    </span>
                                </div>
                                <div className={classes.email_additional}>
                                    {message.withAttachment && (
                                        <Icon className="icon-paperclip" id="icon-paperclip" />
                                    )}
                                    <span className={classes.email_datetime}>
                                        {convertDatetime(message.latestMessage.date)}
                                    </span>
                                </div>
                            </div>
                            <div className={classes.email_content}>
                                <div
                                    className={classes.email_star}
                                    onClick={(e) => handleMessageInFav(e, message.mailsId)}
                                >
                                    {message.isStarred ||
                                        starredEmails.includes(message.mailsId) ? (
                                        <Icon
                                            className="icon-star-checked"
                                            id="icon-star-checked"
                                        />
                                    ) : (
                                        <Icon className="icon-starred" id="icon-starred" />
                                    )}
                                </div>

                                {message.latestMessage && <p>{message.latestMessage.text}</p>}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default MailboxLettersList;
