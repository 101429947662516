import { useState } from 'react';

import { Link, useLocation } from "react-router-dom";

import Icon from "../../ui/Icon";

import classes from './style/asideMenu.module.scss';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../actions/actions';

export default function AsideMenu() {
    const dispatch = useDispatch();
    const location = useLocation();
    const user = useSelector((state) => state.user.info);

	const [hideMenu, setHideMenu] = useState(false)
	let timeout;

    const menuItems = [
        { path: '/textchat', label: 'Chat', icon: 'icon-menu-chat'},
        { path: '/videochat', label: 'Videochat', icon: 'icon-menu-video'},
        { path: '/mailbox', label: 'Email', icon: 'icon-email'},
        { path: '/balance', label: 'Balance', icon: 'icon-settings'},
    ];

    // const handleSideBarArrowClick = () => {
    //     setHideMenu(!hideMenu);
    // };

    const openMenu = () => {
		if (timeout) {
		  	clearTimeout(timeout);
		}
	
		timeout = setTimeout(() => {
		  	if (hideMenu) {
				setHideMenu(false);
		  	}
			}, 500);
	};
	
	const cancelOpenMenu = () => {
		if (timeout) {
		  	clearTimeout(timeout);
		}
	};

    const onLogOut = async (data) => {
        dispatch(logOut(() => {
            window.location.href = '/login';
        }));
    };

    return (
		<aside 
			className={classNames(classes["side_bar"], hideMenu ? classes['side_bar_close'] : '')} 
			onMouseEnter={openMenu}
			onMouseLeave={cancelOpenMenu}
		>
            <div className={classes["side_bar_logo_block"]}>
            {/* {
                !hideMenu ?
                    <Link to="/" className={classes["side_bar_logo"]}>
                        <img src="/cdn/images/logo-big.svg" alt="logo" />
                    </Link>
                    :
                    <Link to="/" className={classNames(classes["side_bar_logo_small"], )}>
                        <img src="/cdn/images/logo-sm.svg" alt="logo" />
                    </Link>
            }

            {
                !hideMenu ?
                    <div className={classes["side_bar_arrow"]} onClick={handleSideBarArrowClick}>
                        <div className={classes["image"]}>
                            <Icon className="icon-arrow-left" id="icon-arrow-left" />
                        </div>
                    </div>
                :
                    null
            } */}
            </div>
            <div className={classes["side_bar_menu_block"]}>
                <h3 className={classes["text_menu_name"]}>#{user?.id} {user?.firstName} {user?.lastName}</h3>
                <h3 className={classes["text_menu"]}>menu</h3>
                <ul>
                    {menuItems.map((item, index) => (
                        <li key={item.path} className={(location.pathname.includes(item.path) || (item.subMenu && item.subMenu.some(subMenu => location.pathname === subMenu.link))) ? classes['active'] : ''}>
                        <Link to={item.path}>
                            <div className={classes['image']}>
                            <Icon className={item.icon} id={item.icon} />
                            </div>
                            {!hideMenu && <span className={classes['open-close']}>{item.label}</span>}
                        </Link>
                        </li>
                    ))}
                    <li>
                        <div className={classes['menu-link']} onClick={onLogOut}>
                            <div className={classes['image']}>
                                <Icon id="icon-power" className="icon-power" />
                            </div>
                            {!hideMenu && <span className={classes['open-close']}>Log Out</span>}
                        </div>
                    </li>
                </ul>
            </div>
        </aside>
    )
}
