/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile, isTablet } from 'react-device-detect';

import PageWrap from '../../app/PageWrap';
import TextChatList from './components/TextChatList';
import TextChatRoom from './components/TextChatRoom';

import classes from './style/textChat.module.scss';
import EmptyChat from '../../components/EmptyChat';
import LadiesList from '../../components/LadiesList';
import { ChatState } from "../../context/chatProvider";
import { SocketContext } from "../../context/socket";
import { useDispatch, useSelector } from 'react-redux';
import { fetchTranslatorChats, setSelectedChatLadyIdAction } from '../../actions/actions';

const TextChat = () => {
    const socket = useContext(SocketContext);
    const [showTextChatRoom, setShowTextChatRoom] = useState(true);
    const chatBlockRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const textChatsLadiesList = useSelector(state => state.textChat.textChatsLadiesList);
    const selectedLadyId = useSelector(state => state.textChat.selectedLadyId);

    const user = useSelector((state) => state.user.info);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    useEffect(() => {
        if(!userInfo || (user && !user?.isLoggedIn)){
            navigate('/login')
        }
    }, [user, userInfo]);

    useEffect(() => {
        dispatch(fetchTranslatorChats());
        return () => {
            dispatch(setSelectedChatLadyIdAction(null))
        }
    }, [dispatch]);

    const {
        selectedChat,
        setSelectedChat,
        setDonor,
    } = ChatState();


    useEffect(() => {
        if (isTablet || isMobile) {
            setShowTextChatRoom(false)
        }
    }, []);

    useEffect(() => {
        if (selectedChat && (!isTablet || !isMobile)) {
            setShowTextChatRoom(true)
        } else (
            setShowTextChatRoom(false)
        )
    }, [selectedChat]);

    const handleInviteAccepted = useCallback((mes) => {
        dispatch(fetchTranslatorChats());
    }, []);

    useEffect(() => {
        socket.on("notification", (mes) => handleInviteAccepted(mes));

        return () => {
            socket.off("notification");
        };
    }, [socket]);

    const onClickLady = (donor) => {
        setDonor(donor)
        setSelectedChat(null)
        navigate(`/textchat/${donor.donorid}`)
        dispatch(setSelectedChatLadyIdAction(donor.donorid))
    }

    return (
        <PageWrap>
            <section className={classes['chat']}>
                <div className="container">
                    {
                        textChatsLadiesList && (
                            <LadiesList ladiesList={textChatsLadiesList} onClickLady={onClickLady} selectedLady={selectedLadyId} />
                        )
                    }
                    {
                        selectedLadyId ? (
                            <div className={classes['chat_block']} ref={chatBlockRef}>
                                {
                                    isTablet || isMobile ? (
                                        !showTextChatRoom ? (
                                            <TextChatList />
                                        ) : (
                                            <TextChatRoom />
                                        )
                                    ) : (
                                        <>
                                            <TextChatList />
                                            {
                                                !selectedChat ? (
                                                    <EmptyChat text="Let’s start Chat with Clients" />
                                                ) : (
                                                    <TextChatRoom />
                                                )
                                            }
                                        </>
                                    )
                                }
                            </div>
                        ) : (
                            <div className={classes['no-ladies']}>
                                <h2>Select Donor</h2>
                            </div>
                        )
                    }
                </div>
            </section>
        </PageWrap>
    );
};

export default TextChat;
