import React from 'react';

import classes from './style/emptyChat.module.scss'

const EmptyChat = ({text}) => {
    return (
        <div className={classes["empty_chat_block"]}>
            <img src="/cdn/images/empty-chat.png" alt="" />
            <p>{text}</p>
        </div>
    );
}

export default EmptyChat;
