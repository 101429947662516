import React from 'react';
import ComposeLetter from '../../../components/ComposeLetter';

import classes from '../style/mailboxReply.module.scss'
import { isMobile } from 'react-device-detect';
import {convertDatetime} from "../../../utils/common";
import classNames from 'classnames';

const MailboxReply = ({onShowReply, senderLetter, latestMessage}) => {
    return (
        <div className={classes["emails_reply"]}>
            <div className={classes["email_letters_list_letter"]}>
                <div className={classes["email_letters_list_letter_header"]}>
                    <div className={classes["email_letters_list_letter_header_personal"]}>
                        {
                            latestMessage.sender.photo ? (
                                <div className={classNames(classes["avatar"], "avatar")}>
                                    <img src={latestMessage.sender.photo} alt="" />
                                </div>
                            ) : (
                                <div className={classNames(classes["avatar"], "avatar")}>
                                    {`${latestMessage.sender.name.charAt(0)}`}
                                </div>
                            )
                        }
                        <div className={classes['name_block']}>
                            <span className={classes['name']}>{latestMessage.sender.name}</span>
                            {
                                isMobile && (
                                    <span className={classes['date']}>{convertDatetime(latestMessage.date)}</span>
                                )
                            }
                        </div>
                    </div>
                    <div className={classes["email_letters_list_letter_date"]}>
                        {
                            !isMobile && (
                                <span>{convertDatetime(latestMessage.date)}</span>
                            )
                        }
                    </div>
                </div>
                <div className={classes["email_letters_list_letter_body"]}>

                    <p>
                        {latestMessage.text}
                    </p>
                </div>
                <div className={classes["email_letters_reply"]}>
                    <ComposeLetter closeCompose={onShowReply} isReply={true} senderLetter={senderLetter} />
                </div>
            </div>
        </div>
    );
}

export default MailboxReply;
