/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import PageWrap from "../../app/PageWrap";
import MailboxLeftMenu from "./compoments/MailboxLeftMenu";
import MailboxLettersList from "./compoments/MailboxLettersList";
import MailboxReadLetter from "./compoments/MailboxReadLetter";

import classes from "./style/mailbox.module.scss";
import LadiesList from "../../components/LadiesList";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchEmailsForDonor,
    fetchTranslatorEmails,
    setSelectedLadyIdAction
} from "../../actions/emailsActions";
import { SocketContext } from "../../context/socket";

const Mailbox = () => {
    const navigate = useNavigate();
    const { mailId, donorId } = useParams();
    const [showMenuMobile, setShowMenuMobile] = useState(false);
    const dispatch = useDispatch();
    const emailsList = useSelector((state) => state.email.emails);
    const donorsList = useSelector((state) => state.email.donorsList);
    const selectedLadyId = useSelector((state) => state.email.selectedLadyId);
    const socket = useContext(SocketContext);
    
    const user = useSelector((state) => state.user.info);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    useEffect(() => {
        if(!userInfo || (user && !user?.isLoggedIn)){
            navigate('/login')
        }
    }, [user, userInfo]);

    useEffect(() => {
        dispatch(fetchTranslatorEmails())
        if (donorId) {
            dispatch(setSelectedLadyIdAction(donorId))
        }
    }, [dispatch]);

    const handleNotification = useCallback((mes) => {
        dispatch(fetchTranslatorEmails())
        dispatch(fetchEmailsForDonor(selectedLadyId))
    }, [selectedLadyId]);

    useEffect(() => {
        if(user){
            socket.on('notification', (mes) => handleNotification(mes))
        }

        return () => {
            socket.off('notification')
        }
    }, [socket, selectedLadyId, user])

    const onChangeBox = (path) => {
        if (!path) {
            navigate(`/mailbox/${selectedLadyId}`);
        } else {
            navigate(`/mailbox/${selectedLadyId}/${path}`);
        }
        setShowMenuMobile(false)
    };

    const showMessage = (id) => {
        navigate(`/mailbox/${selectedLadyId}/readletter/${id}`);
    };

    const onShowMenuMobile = () => {
        setShowMenuMobile(!showMenuMobile)
    }

    const onClickLady = (lady) => {
        dispatch(setSelectedLadyIdAction(lady.donorid))
        navigate(`/mailbox/${lady.donorid}/inbox`)
    }

    useEffect(() => {
        if (selectedLadyId) {
            dispatch(fetchEmailsForDonor(selectedLadyId))
        }
    }, [dispatch, selectedLadyId]);

    return (
        <PageWrap>
            <section className={classes["email"]}>
                <div className="container">
                    {
                        donorsList && (
                            <LadiesList ladiesList={donorsList} onClickLady={onClickLady} selectedLady={selectedLadyId} />
                        )
                    }

                    {
                        selectedLadyId ? (
                            <div className={classes["email_block"]}>
                                <MailboxLeftMenu
                                    onChangeBox={onChangeBox}
                                    showMenuMobile={showMenuMobile}
                                />
                                {mailId ? (
                                    <MailboxReadLetter mailId={mailId} />
                                ) : (
                                    <MailboxLettersList
                                        emailMessages={emailsList}
                                        showMessage={showMessage}
                                        onShowMenuMobile={onShowMenuMobile}
                                        showMenuMobile={showMenuMobile}
                                    />
                                )}
                            </div>
                        ) : (
                            <div className={classes['no-ladies']}>
                                <h2>Select Donor</h2>
                            </div>
                        )
                    }
                </div>
            </section>
        </PageWrap>
    );
};

export default Mailbox;
