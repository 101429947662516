import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ChatContext = createContext();

const ChatProvider = ({ children }) => {
    const [selectedChat, setSelectedChat] = useState();
    const [user, setUser] = useState();
    const [notifications, setNotifications] = useState([]);
    const [chats, setChats] = useState([]);
    const [donor, setDonor] = useState(null);
    const [reload, setReload] = useState(null);
    const [unreadMessages, setUnreadMessages] = useState([]);
    const [videoRooms, setVideoRooms] = useState(null);
    const [current, setCurrent] = useState(null);
    const [selectedLadyId, setSelectedLadyId] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        setUser(userInfo);

        if (!userInfo) navigate("/");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ChatContext.Provider
            value={{
                selectedChat,
                setSelectedChat,
                user,
                setUser,
                notifications,
                setNotifications,
                chats,
                setChats,
                donor,
                setDonor,
                reload,
                setReload,
                unreadMessages,
                setUnreadMessages,
                videoRooms,
                setVideoRooms,
                current,
                setCurrent,
                selectedLadyId,
                setSelectedLadyId
            }}
        >
            {children}
        </ChatContext.Provider>
    );
};

export const ChatState = () => {
    return useContext(ChatContext);
};

export default ChatProvider;
