/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ChatState } from '../../context/chatProvider';
import { fetchMessages, resetMessages } from '../../actions/actions';

import classes from './style/chatMessages.module.scss'
import classNames from 'classnames';
import { SocketContext } from '../../context/socket';
import { useParams } from 'react-router-dom';

export default function ChatMessages({videochat}) {

    const dispatch = useDispatch();
    const socket = useContext(SocketContext);
    const {chatId} = useParams();
    
    const messagesListRef = useRef();
    const messagesRef = useRef();

    const user = useSelector((state) => state.user.info);
    const messagesById = useSelector((state) => state.textChat.messagesById);

    const [messagesList, setMessagesList] = useState([]);
    const [firstScroll, setFirstScroll] = useState(true);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const { selectedChat, donor, current } = ChatState();

    useEffect(() => {
        if (chatId) {
            dispatch(resetMessages(parseInt(chatId)));
            dispatch(fetchMessages(parseInt(chatId), 1, 20));
        }
        if(selectedChat && !chatId){
            dispatch(resetMessages(parseInt(selectedChat)));
            dispatch(fetchMessages(parseInt(selectedChat), 1, 20));
        }
        if (currentPage > 1) {
            setCurrentPage(1);
        }
    }, [dispatch, chatId, selectedChat]);

    useEffect(() => {
        if (messagesById) {
            setMessagesList(messagesById[selectedChat]);
        }
    }, [messagesById, selectedChat]);

    useEffect(() => {
        setFirstScroll(true)
    }, [selectedChat]);

    useEffect(() => {
        if (messagesListRef && messagesListRef.current && firstScroll) {
            const messagesContainer = messagesListRef.current;
            const lastMessage = messagesContainer.lastChild;

            if (lastMessage) {
                lastMessage.scrollIntoView({ behavior: "smooth" });
                setTimeout(() => {
                    setFirstScroll(false);
                }, 500);
            }
        }
    }, [messagesList, selectedChat, firstScroll]);

    const sortedMessages = messagesList && messagesList.messages ? [...messagesList.messages].sort((a, b) => new Date(a.date) - new Date(b.date)) : [];

    const groupedMessages = sortedMessages.reduce((result, message) => {
        const date = new Date(message.date).toLocaleDateString();
        if (!result[date]) {
            result[date] = [];
        }
        result[date].push(message);
        return result;
    }, {});

    const messageDates = Object.keys(groupedMessages);

    const today = new Date().toLocaleDateString();
    const yesterday = new Date(Date.now() - 86400000).toLocaleDateString();

    const handleInviteAccepted = useCallback((mes) => {
        if (user && (mes.id == chatId || mes.id == selectedChat)) {
            const newMessageData = {
                messageText: mes.latestMessage.message,
                date: mes.latestMessage.created_at,
                sender: mes.latestMessage.sender.id === user.id ? "donor" : "client"
            };
            if (!messagesList.messages.find(message => message.messageText === newMessageData.messageText && message.date === newMessageData.date)) {
                setMessagesList((prevMessages) => ({
                    ...prevMessages,
                    messages: [...prevMessages.messages, newMessageData],
                }));
    
                if (messagesListRef.current) {
                    const messagesContainer = messagesListRef.current;
                    const lastMessage = messagesContainer.lastChild;
                    if (lastMessage) {
                        lastMessage.scrollIntoView({ behavior: "smooth" });
                        setScrollPosition(0)
                    }
                }
            }
        }
    }, [user, messagesList, messagesById, chatId, selectedChat]);

    useEffect(() => {
        socket.on("message received", handleInviteAccepted);
    
        return () => {
            socket.off("message received", handleInviteAccepted);
        };
    }, [socket, handleInviteAccepted, messagesById, chatId, selectedChat]);

    const loadMoreMessages = useCallback(async () => {
        try {
            setTimeout(async () => {
                await dispatch(fetchMessages(selectedChat, currentPage + 1, 20));
                setCurrentPage((prevPage) => prevPage + 1);
            }, 1000); 
        } catch (error) {
            console.log(error);
        }
    }, [currentPage, dispatch, selectedChat, setCurrentPage]);

    const handleScroll = useCallback(() => {
        const messagesContainer = messagesRef.current;
        if (messagesContainer) {
            const atTop = messagesContainer.scrollTop === 0;
    
            if (atTop) {
                setScrollPosition(messagesContainer.scrollHeight - messagesContainer.scrollTop);
                loadMoreMessages();
            }
        }
    }, [loadMoreMessages]);

    useEffect(() => {
        const handleScrollEvent = () => {
            if (!firstScroll && messagesList && (messagesList.messages.length <= messagesList.totalMessagesCount)) {
                handleScroll();
            }
        };
    
        const messagesContainer = messagesRef.current;
        if (messagesContainer) {
            messagesContainer.addEventListener("scroll", handleScrollEvent);
            messagesContainer.scrollTop = messagesContainer.scrollHeight - scrollPosition;
        }
    
        return () => {
            if (messagesContainer) {
                messagesContainer.removeEventListener("scroll", handleScrollEvent);
            }
        };
    }, [handleScroll, messagesList, firstScroll, scrollPosition]);

    return (
        <div className={classes["scroll-container"]} ref={messagesRef}>
            <div className={classNames(classes["chat_messages"], videochat && classes['videochat'])} ref={messagesListRef}>
                <div className={classes["user_message_filler"]} />
                {messageDates.map((date) => (
                    <React.Fragment key={date}>
                        <div className={classes["chat_date_separator"]}>
                            <span>
                                {date === today
                                    ? "Today"
                                    : date === yesterday
                                        ? "Yesterday"
                                        : date}
                            </span>
                        </div>
                        {groupedMessages[date].map((message) => (
                            <div
                                key={message.id}
                                className={classNames(
                                    classes["user_message"],
                                    message.sender == "donor" && classes["self_user"]
                                )}
                            >
                                {message.sender == "client" ? (
                                    current?.photo ? (
                                        <img src={current?.photo} alt="" />
                                    ) : (
                                        <div className={classNames(classes["avatar"], "avatar")}>
                                            {`${current?.userName.charAt(0)}`}
                                        </div>
                                    )
                                ) : donor.photo ? (
                                    <img src={donor?.photo} alt="" />
                                ) : (
                                    <div className={classNames(classes["avatar"], "avatar")}>
                                        {`${donor?.name.charAt(0)}`}
                                    </div>
                                )}
                                <ul className={classes["user_messages"]}>
                                    <li>{message.messageText}</li>
                                </ul>
                            </div>
                        ))}
                    </React.Fragment>
                ))}
            </div>
        </div>
    )
}
