import { FETCH_TRANSLATOR_VIDEOCHATS_FAILURE, FETCH_TRANSLATOR_VIDEOCHATS_REQUEST, FETCH_TRANSLATOR_VIDEOCHATS_SUCCESS, SET_SELECTED_LADY_ID } from "../actions/types";


const initialState = {
    videoChatDonorsList: [],
    selectedLadyId: null,
    loading: false,
    error: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TRANSLATOR_VIDEOCHATS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_TRANSLATOR_VIDEOCHATS_SUCCESS:
            return {
                ...state,
                videoChatDonorsList: action.payload,
                loading: false,
                error: null,
            };
        case FETCH_TRANSLATOR_VIDEOCHATS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case SET_SELECTED_LADY_ID:
            return {
                ...state,
                selectedLadyId: action.payload
            };
        default:
            return state;
    }
};

export default reducer;
