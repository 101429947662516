import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    FETCH_MESSAGES_FOR_EMAIL_REQUEST,
    FETCH_MESSAGES_FOR_EMAIL_SUCCESS, FETCH_MESSAGES_FOR_EMAIL_FAILURE, SET_SELECTED_CHAT_LADY_ID, FETCH_TRANSLATOR_VIDEOCHATS_REQUEST, FETCH_TRANSLATOR_VIDEOCHATS_SUCCESS, FETCH_TRANSLATOR_VIDEOCHATS_FAILURE, SET_SELECTED_LADY_ID, SOCKET_CONNECTION
} from "./types";
import {FETCH_EMAILS_SUCCESS,FETCH_EMAILS_FAILURE,FETCH_EMAILS_REQUEST,STAR_EMAIL_FAILURE,STAR_EMAIL_SUCCESS,STAR_EMAIL_REQUEST,
FETCH_TRANSLATOR_EMAILS_REQUEST,FETCH_TRANSLATOR_EMAILS_FAILURE,FETCH_TRANSLATOR_EMAILS_SUCCESS,
SEND_EMAIL_SUCCESS,SEND_EMAIL_FAILURE,SEND_EMAIL_REQUEST,UPDATE_EMAIL_NOTIFICATION_SUCCESS,
UPDATE_EMAIL_NOTIFICATION_REQUEST,UPDATE_EMAIL_NOTIFICATION_FAILURE} from "./types";
import axios from "axios";
import {
  FETCH_TRANSLATOR_CHATS_REQUEST,
  FETCH_TRANSLATOR_CHATS_SUCCESS,
  FETCH_TRANSLATOR_CHATS_FAILURE,
  FETCH_CHATS_REQUEST,
  FETCH_CHATS_SUCCESS,
  FETCH_CHATS_FAILURE,
  FETCH_MESSAGES_REQUEST,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_FAILURE,
  RESET_MESSAGES,
} from "./types";
// import instance from "../context/api";

export const loginRequest = (credentials) => ({
  type: LOGIN_REQUEST,
  payload: credentials,
});

export const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user,
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

// login

export const fetchUserRequest = () => ({
  type: "FETCH_USER_REQUEST",
});

export const fetchUserSuccess = (info) => ({
  type: "FETCH_USER_SUCCESS",
  payload: info,
});

export const fetchUserFailure = (error) => ({
  type: "FETCH_USER_FAILURE",
  payload: error,
});

export const getUser = (userId) => {
  return (dispatch) => {
    dispatch(fetchUserRequest());
    const user = JSON.parse(localStorage.getItem("userInfo"));
    let config = {
        headers: {
            "Content-type": "application/json",
            "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
        },
    };
    if(user){
        config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${user.token}`,
                "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
            },
        };
    }
    if(user){
      axios
        .get(
          `https://api.surrogacy-platform.com/api/users/getUser/${user.userId}`, config
        )
        .then((response) => {
          const info = response.data;
          dispatch(fetchUserSuccess(info));
        })
        .catch((error) => {
          dispatch(fetchUserFailure(error.message));
        });
    }
  };
};

export const fetchTranslatorChats = () => {
  return (dispatch) => {
    dispatch(fetchTranslatorChatsRequest());
    const user = JSON.parse(localStorage.getItem("userInfo"));
    if(user){
      const headers = {
        "Content-type": "application/json",
        Authorization: `Bearer ${user.token}`,
        "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
      };
      axios
        .get(
          "https://api.surrogacy-platform.com/api/donors/translator-chats-list",
          { headers }
        )
        .then((response) => {
          dispatch(fetchTranslatorChatsSuccess(response.data));
        })
        .catch((error) => {
          dispatch(fetchTranslatorChatsFailure(error.message));
        });

    }
  };
};

export const fetchTranslatorChatsRequest = () => ({
  type: FETCH_TRANSLATOR_CHATS_REQUEST,
});

export const fetchTranslatorChatsSuccess = (data) => ({
  type: FETCH_TRANSLATOR_CHATS_SUCCESS,
  payload: data,
});

export const fetchTranslatorChatsFailure = (error) => ({
  type: FETCH_TRANSLATOR_CHATS_FAILURE,
  payload: error,
});

export const fetchChats = (donorId) => {
  return async (dispatch) => {
    dispatch(fetchChatsRequest());

    try {
      const user = JSON.parse(localStorage.getItem("userInfo"));
      const headers = {
        "Content-type": "application/json",
        Authorization: `Bearer ${user.token}`,
        "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
      };
      const response = await axios.get(`https://api.surrogacy-platform.com/api/conversation/chats-by-donor-and-translator/${donorId}`, { headers })
    
      const data = response.data;
      dispatch(fetchChatsSuccess(donorId, data));
      return data;
    } catch (error) {
      dispatch(fetchChatsFailure(donorId, error.message));
      throw error;
    }
  };
};

export const fetchChatsRequest = () => {
  return {
    type: FETCH_CHATS_REQUEST
  };
};

export const fetchChatsSuccess = (donorId, chats) => {
  return {
    type: FETCH_CHATS_SUCCESS,
    donorId,
    payload: chats,
  };
};

export const fetchChatsFailure = (donorId, error) => {
  return {
    type: FETCH_CHATS_FAILURE,
    donorId,
    payload: error,
  };
};

export const fetchMessages = (chatId, page, limit) => {
  return (dispatch) => {
    dispatch(fetchMessagesRequest());
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${user.token}`,
      "x-api-key": "aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
    };
    axios.get(`https://api.surrogacy-platform.com/api/messages/conversation-mes/${chatId}?page=${page}&limit=${limit}`, {headers})
      .then(response => {
        const messages = response.data.messages;
        const totalMessagesCount = response.data.totalMessagesCount
        dispatch(fetchMessagesSuccess(chatId, messages, totalMessagesCount));
      })
      .catch(error => {
        dispatch(fetchMessagesFailure(error.message));
      });
  };
};

export const setSelectedChatLadyIdAction = (ladyId) => {
  return {
      type: SET_SELECTED_CHAT_LADY_ID,
      payload: ladyId
  };
};

export const resetMessages = (chatId) => ({
  type: RESET_MESSAGES,
  payload: chatId,
});

export const fetchEmailsRequest = () => ({
    type: FETCH_EMAILS_REQUEST,
});

export const fetchEmailsSuccess = (emails) => ({
    type: FETCH_EMAILS_SUCCESS,
    payload: emails,
});

export const fetchEmailsFailure = (error) => ({
    type: FETCH_EMAILS_FAILURE,
    payload: error,
});

export const fetchMessagesForEmailRequest = () => ({
    type: FETCH_MESSAGES_FOR_EMAIL_REQUEST,
});

export const fetchMessagesForEmailSuccess = (messages) => ({
    type: FETCH_MESSAGES_FOR_EMAIL_SUCCESS,
    payload: messages,
});

export const fetchMessagesForEmailFailure = (error) => ({
    type: FETCH_MESSAGES_FOR_EMAIL_FAILURE,
    payload: error,
});
export const starEmailRequest = () => ({
    type: STAR_EMAIL_REQUEST,
});

export const starEmailSuccess = (id) => ({
    type: STAR_EMAIL_SUCCESS,
    payload: id,
});

export const starEmailFailure = (error) => ({
    type: STAR_EMAIL_FAILURE,
    payload: error,
});

export const fetchTranslatorEmailsRequest = () => ({
    type: FETCH_TRANSLATOR_EMAILS_REQUEST,
});

export const fetchTranslatorEmailsSuccess = (emails) => ({
    type: FETCH_TRANSLATOR_EMAILS_SUCCESS,
    payload: emails,
});

export const fetchTranslatorEmailsFailure = (error) => ({
    type: FETCH_TRANSLATOR_EMAILS_FAILURE,
    payload: error,
});

export const sendEmailRequest = () => ({
    type: SEND_EMAIL_REQUEST,
});

export const sendEmailSuccess = (emailData) => ({
    type: SEND_EMAIL_SUCCESS,
    payload: emailData,
});

export const sendEmailFailure = (error) => ({
    type: SEND_EMAIL_FAILURE,
    payload: error,
});

export const updateEmailNotificationRequest = () => ({
    type: UPDATE_EMAIL_NOTIFICATION_REQUEST,
});

export const updateEmailNotificationSuccess = (mailId) => ({
    type: UPDATE_EMAIL_NOTIFICATION_SUCCESS,
    payload: mailId,
});

export const updateEmailNotificationFailure = (error) => ({
    type: UPDATE_EMAIL_NOTIFICATION_FAILURE,
    payload: error,
});



export const fetchMessagesRequest = () => {
  return {
    type: FETCH_MESSAGES_REQUEST,
  };
};

export const fetchMessagesSuccess = (chatId, messages, totalMessagesCount) => {
  return {
    type: FETCH_MESSAGES_SUCCESS,
    payload: {chatId, messages, totalMessagesCount},
  };
};

export const fetchMessagesFailure = (error) => {
  return {
    type: FETCH_MESSAGES_FAILURE,
    payload: error,
  };
};

export const fetchNotifications = () => async (dispatch) => {
  try {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const headers = {
      Authorization: `Bearer ${user.token}`,
      "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
    };
    const response = await axios.get(
      'https://api.surrogacy-platform.com/api/users/notifications',
      {headers}
    );

    dispatch({
      type: 'FETCH_NOTIFICATIONS_SUCCESS',
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: 'FETCH_NOTIFICATIONS_FAILURE',
      payload: error.message,
    });
  }
};

export const updateNotification = (id, donorId) => async (dispatch) => {
  try {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const headers = {
      Authorization: `Bearer ${user.token}`,
      "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
    };
    const response = await axios.post(
      `https://api.surrogacy-platform.com/api/users/update-notification?chatId=${id}&plus=false&userId=${user.userId}&donorId=${donorId}`,
      null,
      {headers}
    );

    dispatch({
      type: 'UPDATE_NOTIFICATION_SUCCESS',
      payload: response.data, // если API возвращает какие-то данные
    });
  } catch (error) {
    dispatch({
      type: 'UPDATE_NOTIFICATION_FAILURE',
      payload: error.message,
    });
  }
};

export const fetchTranslatorVideoChatsRequest = () => ({
  type: FETCH_TRANSLATOR_VIDEOCHATS_REQUEST,
});

export const fetchTranslatorVideoChatsSuccess = (data) => ({
  type: FETCH_TRANSLATOR_VIDEOCHATS_SUCCESS,
  payload: data,
});

export const fetchTranslatorVideoChatsFailure = (error) => ({
  type: FETCH_TRANSLATOR_VIDEOCHATS_FAILURE,
  payload: error,
});

export const setSelectedLadyIdAction = (ladyId) => {
  return {
      type: SET_SELECTED_LADY_ID,
      payload: ladyId
  };
};

export const setSocketConnection = (res) => ({
  type: SOCKET_CONNECTION,
  payload: res,
});

export const uploadPhotoRequest = () => ({
  type: 'UPLOAD_PHOTO_REQUEST',
});

export const uploadPhoto = (formData) => async (dispatch) => {
  dispatch(uploadPhotoRequest());
  try {
    const user = JSON.parse(localStorage.getItem('userInfo'));
    const config = {
      headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${user.token}`,
          "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
      },
    };
      const response = await axios.post(`https://api.surrogacy-platform.com/api/users/uploadPhoto`, formData, config);
      dispatch(uploadPhotoSuccess(response.data));
  } catch (error) {
      dispatch(uploadPhotoFailure(error.message));
  }
};

const uploadPhotoSuccess = (data) => ({
  type: 'UPLOAD_PHOTO_SUCCESS',
  payload: data,
});

const uploadPhotoFailure = (error) => ({
  type: 'UPLOAD_PHOTO_FAILURE',
  payload: error,
});

export const getBalanceInvoicesRequest = () => ({
  type: 'GET_BALANCE_INVOICES_REQUEST',
});

export const getBalanceInvoices = (id) => async (dispatch) => {
  dispatch(getBalanceInvoicesRequest());
  try {
    const user = JSON.parse(localStorage.getItem('userInfo'));
    const config = {
      headers: {
        "Content-type": "application/json",
          Authorization: `Bearer ${user.token}`,
          "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
      },
    };
      const response = await axios.get(`https://api.surrogacy-platform.com/api/admin/translator-invoices/${id}`, config);
      dispatch(getBalanceInvoicesSuccess(response.data));
  } catch (error) {
      dispatch(getBalanceInvoicesFailure(error.message));
  }
};

const getBalanceInvoicesSuccess = (data) => ({
  type: 'GET_BALANCE_INVOICES_SUCCESS',
  payload: data,
});

const getBalanceInvoicesFailure = (error) => ({
  type: 'GET_BALANCE_INVOICES_FAILURE',
  payload: error,
});

export const logoutRequest = (credentials) => ({
  type: 'LOGOUT_REQUEST',
  payload: credentials,
});

export const logoutSuccess = () => ({
  type: 'LOGOUT_SUCCESS'
});

export const logoutFailure = (error) => ({
  type: 'LOGOUT_FAILURE',
  error
});

export const logOut = (onSuccessRedirect) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem('userInfo'));
  const config = {
      headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.token}`,
          "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
      },
  };

  dispatch(logoutRequest());

  try {
      const response = await axios.patch('https://api.surrogacy-platform.com/api/auth/logout', {}, config);
      
      dispatch(logoutSuccess(response.data));

      if (onSuccessRedirect) {
          onSuccessRedirect('/login');
      }
  } catch (error) {
      dispatch(logoutFailure(error));
  }
}