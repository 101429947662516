import axios from "axios";
import { fetchTranslatorVideoChatsFailure, fetchTranslatorVideoChatsRequest, fetchTranslatorVideoChatsSuccess } from "./actions";

export const fetchTranslatorVideoChats = () => {
    return (dispatch) => {
      dispatch(fetchTranslatorVideoChatsRequest());
      const user = JSON.parse(localStorage.getItem("userInfo"));
      if(user) {
        const headers = {
          Authorization: `Bearer ${user.token}`,
          "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
        };
        axios
          .get(
            "https://api.surrogacy-platform.com/api/donors/translator-video-list",
            { headers }
          )
          .then((response) => {
            dispatch(fetchTranslatorVideoChatsSuccess(response.data));
          })
          .catch((error) => {
            dispatch(fetchTranslatorVideoChatsFailure(error.message));
          });
      }
    };
};