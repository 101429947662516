import {
    fetchEmailsRequest,
    fetchEmailsSuccess,
    fetchEmailsFailure,
    fetchMessagesForEmailSuccess,
    fetchMessagesForEmailFailure,
    fetchMessagesForEmailRequest,
    starEmailRequest,
    starEmailSuccess,
    starEmailFailure,
    fetchTranslatorEmailsRequest,
    fetchTranslatorEmailsSuccess,
    fetchTranslatorEmailsFailure,
    sendEmailRequest,
    sendEmailSuccess,
    sendEmailFailure,
    updateEmailNotificationRequest, updateEmailNotificationSuccess, updateEmailNotificationFailure
} from './actions';
import instance from "../context/api";
import {SET_SELECTED_LADY_ID} from "./types";

export const fetchEmailsForDonor =  (donorId) => async (dispatch) => {
    dispatch(fetchEmailsRequest());
    try {
        const response = await instance.get(`conversation/emails-by-donor-and-translator/${donorId}`);

        dispatch(fetchEmailsSuccess(response.data));
    } catch (error) {
        dispatch(fetchEmailsFailure(error.message));
    }
};

export const fetchMessagesForEmail = (page, limit, mailId) => async (dispatch) => {
    dispatch(fetchMessagesForEmailRequest());
    try {
        const response = await instance.get(`messages/messages-client-by-mail-id/${mailId}`, {
            params: { page, limit }
        });
        dispatch(fetchMessagesForEmailSuccess(response.data));
    } catch (error) {
        dispatch(fetchMessagesForEmailFailure(error.message));
    }
};

export const starEmail = (id) => async (dispatch) => {
    dispatch(starEmailRequest());
    try {
        const user = JSON.parse(localStorage.getItem("userInfo"));
        const headers = {
        Authorization: `Bearer ${user.token}`,
        };
        const response = await instance.post(`conversation/star/${id}`, {headers});


        dispatch(starEmailSuccess(response.data)); // Можливо, ви захочете зберегти повернуті дані
    } catch (error) {
        dispatch(starEmailFailure(error.message));
    }
};


// Action Creator for Async Call
export const fetchTranslatorEmails = () => async (dispatch) => {
    dispatch(fetchTranslatorEmailsRequest());
    try {
        const response = await instance.get('donors/translator-email-list');
        dispatch(fetchTranslatorEmailsSuccess(response.data));
    } catch (error) {
        dispatch(fetchTranslatorEmailsFailure(error.message));
    }
};

// actionCreators.js

export const sendEmail = (emailData) => async (dispatch) => {
    dispatch(sendEmailRequest());
    return instance.post('messages', emailData)
        .then(response => {
            dispatch(sendEmailSuccess(response.data));
            return response.data; 

        })
        .catch(error => {
            dispatch(sendEmailFailure(error.message));
            return Promise.reject(error); 
        });
};

export const updateEmailNotification = (mailId) => async (dispatch) => {
    dispatch(updateEmailNotificationRequest());
    try {
        // eslint-disable-next-line no-unused-vars
        const response = await instance.patch('conversation/updateEmailNotify', { mailIds: [mailId] });
        dispatch(updateEmailNotificationSuccess(mailId));
    } catch (error) {
        dispatch(updateEmailNotificationFailure(error.message));
    }
};


export const setSelectedLadyIdAction = (ladyId) => {
    return {
        type: SET_SELECTED_LADY_ID,
        payload: ladyId
    };
};


// actions.js








