import {
    BrowserRouter as Router,
    useRoutes,
} from "react-router-dom";
import { Provider } from 'react-redux'; 
import store from '../store'; 
import LoginPage from "../pages/LoginPage";
import VideoCalls from "../pages/VIdeoCalls";
import TextChat from "../pages/TextChat";
import Mailbox from "../pages/Mailbox";
import ChatProvider from "../context/chatProvider";
import {SocketContext, socket} from '../context/socket';
import BalancePage from "../pages/BalancePage";

const Pages = () => {
    let routes = useRoutes([
        {
            path: "/",
            element: <LoginPage />
        },
        {
            path: "/balance",
            element: <BalancePage />
        },
        {
            path: "/login",
            element: <LoginPage />
        },
        // {
        //     path: "/login",
        //     element: <LoginPage />
        // },
        {
            path: "/videochat",
            element: <VideoCalls />,
            breadcrumb: null
        },
        {
            path: "/videochat/:donorId",
            element: <VideoCalls />,
            breadcrumb: null
        },
        {
            path: "/videochat/:donorId/:chatId",
            element: <VideoCalls />
        },
        {
            path: "/videochat/:donorId/:chatId/:roomId",
            element: <VideoCalls />
        },
        {
            path: "/textchat",
            element: <TextChat />
        },
        {
            path: "/textchat/:donorId",
            element: <TextChat />
        },
        {
            path: "/textchat/:donorId/:chatId",
            element: <TextChat />
        },
        {
            path: "/mailbox",
            element: <Mailbox />
        },
        {
            path: "/mailbox/:donorId",
            element: <Mailbox />
        },
        {
            path: "/mailbox/:donorId/:box",
            element: <Mailbox />
        },
        {
            path: "/mailbox/:donorId/readletter/:mailId",
            element: <Mailbox />
        },
        // {
        //     path: "/mailbox/readletter/:mailId",
        //     element: <Mailbox />
        // }

    ]);
    return routes;
};

const AppWrapper = () => {
    return (
        <SocketContext.Provider value={socket}>
            <Provider store={store}>
                <Router>
                    <ChatProvider>
                        <Pages />
                    </ChatProvider>
                </Router>
            </Provider>
        </SocketContext.Provider>
    );
};

export default AppWrapper;
