export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

// actionTypes.js

export const UPDATE_EMAIL_NOTIFICATION_REQUEST = 'UPDATE_EMAIL_NOTIFICATION_REQUEST';
export const UPDATE_EMAIL_NOTIFICATION_SUCCESS = 'UPDATE_EMAIL_NOTIFICATION_SUCCESS';
export const UPDATE_EMAIL_NOTIFICATION_FAILURE = 'UPDATE_EMAIL_NOTIFICATION_FAILURE';


// Action Types
export const FETCH_TRANSLATOR_EMAILS_REQUEST = 'FETCH_TRANSLATOR_EMAILS_REQUEST';
export const FETCH_TRANSLATOR_EMAILS_SUCCESS = 'FETCH_TRANSLATOR_EMAILS_SUCCESS';
export const FETCH_TRANSLATOR_EMAILS_FAILURE = 'FETCH_TRANSLATOR_EMAILS_FAILURE';


export const FETCH_MESSAGES_FOR_EMAIL_REQUEST = 'FETCH_MESSAGES_FOR_EMAIL_REQUEST';
export const FETCH_MESSAGES_FOR_EMAIL_SUCCESS = 'FETCH_MESSAGES_FOR_EMAIL_SUCCESS';
export const FETCH_MESSAGES_FOR_EMAIL_FAILURE = 'FETCH_MESSAGES_FOR_EMAIL_FAILURE';

export const FETCH_EMAILS_FAILURE = 'FETCH_EMAILS_FAILURE';


export const FETCH_EMAILS_REQUEST = 'FETCH_EMAILS_REQUEST';
export const FETCH_EMAILS_SUCCESS = 'FETCH_EMAILS_SUCCESS';

export const GetConversationRequest = 'GET_CONVERSATION_REQUEST'
export const GetConversationSuccess = 'GET_CONVERSATION_SUCCESS'
export const GetConversationFailed = 'GET_CONVERSATION_FAILED'

export const FETCH_TRANSLATOR_CHATS_REQUEST = 'FETCH_TRANSLATOR_CHATS_REQUEST';
export const FETCH_TRANSLATOR_CHATS_SUCCESS = 'FETCH_TRANSLATOR_CHATS_SUCCESS';
export const FETCH_TRANSLATOR_CHATS_FAILURE = 'FETCH_TRANSLATOR_CHATS_FAILURE';

export const FETCH_CHATS_REQUEST = 'FETCH_CHATS_REQUEST';
export const FETCH_CHATS_SUCCESS = 'FETCH_CHATS_SUCCESS';
export const FETCH_CHATS_FAILURE = 'FETCH_CHATS_FAILURE';

export const FETCH_MESSAGES_REQUEST = 'FETCH_MESSAGES_REQUEST';
export const RESET_MESSAGES = 'RESET_MESSAGES';
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_FAILURE = 'FETCH_MESSAGES_FAILURE';

export const STAR_EMAIL_REQUEST = 'STAR_EMAIL_REQUEST';
export const STAR_EMAIL_SUCCESS = 'STAR_EMAIL_SUCCESS';
export const STAR_EMAIL_FAILURE = 'STAR_EMAIL_FAILURE';

export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE';

export const SET_SELECTED_LADY_ID = 'SET_SELECTED_LADY_ID';
export const SET_SELECTED_CHAT_LADY_ID = 'SET_SELECTED_CHAT_LADY_ID';

export const FETCH_TRANSLATOR_VIDEOCHATS_REQUEST = 'FETCH_TRANSLATOR_VIDEOCHATS_REQUEST';
export const FETCH_TRANSLATOR_VIDEOCHATS_SUCCESS = 'FETCH_TRANSLATOR_VIDEOCHATS_SUCCESS';
export const FETCH_TRANSLATOR_VIDEOCHATS_FAILURE = 'FETCH_TRANSLATOR_VIDEOCHATS_FAILURE';

export const SOCKET_CONNECTION = 'SOCKET_CONNECTION';