/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../../ui/Icon";

import classes from "../style/textChatList.module.scss";
import classNames from "classnames";
import { ChatState } from "../../../context/chatProvider";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchChats,
    fetchNotifications,
    fetchTranslatorChats,
    updateNotification,
} from "../../../actions/actions";
import { SocketContext } from "../../../context/socket";

const TextChatList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const donorChats = useSelector((state) => state.textChat.donorChats);
    const messageNotification = useSelector(
        (state) => state.textChat.notifications
    );
    const [unreadMessages, setUnreadMessages] = useState({});
    const socket = useContext(SocketContext);

    const {
        selectedChat,
        setSelectedChat,
        setChats,
        chats,
        donor,
        setCurrent,
    } = ChatState();

    useEffect(() => {
        if (donor) {
            dispatch(fetchChats(donor.donorid));
            dispatch(fetchNotifications());
        }
    }, [dispatch, donor]);

    useEffect(() => {
        if (donorChats && donor) {
            setChats(donorChats[donor.donorid]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [donorChats, donor]);

    const showActiveChat = (currentChat) => {
        setSelectedChat(currentChat.chatId);
        setCurrent(currentChat);
        navigate(`/textchat/${donor.donorid}/${currentChat.chatId}`);
        if (unreadMessages) {
            dispatch(updateNotification(currentChat.chatId, donor.donorid));
            setTimeout(() => {
                dispatch(fetchTranslatorChats());
            }, 500);
        }
    };

    useEffect(() => {
        const handleInviteAccepted = (mes) => {
            dispatch(fetchChats(donor.donorid))
            setUnreadMessages((prevUnreadMessages) => {
                const updatedUnreadMessages = { ...prevUnreadMessages };

                mes.messageNotification.forEach((notification) => {
                    if (notification.chatId !== parseInt(selectedChat, 10)) {
                        updatedUnreadMessages[notification.chatId] = notification.count;
                    }
                });

                return updatedUnreadMessages;
            });
        };

        const unreadMessagesObj = {};
        messageNotification.forEach((notification) => {
            unreadMessagesObj[notification.chatId] = notification.count;
        });
        setUnreadMessages(unreadMessagesObj);

        socket.on("notification", handleInviteAccepted);

        return () => {
            socket.off("notification", handleInviteAccepted);
        };
    }, [messageNotification, socket]);

    const formatDate = (originalDate) => {
        const date = new Date(originalDate);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
    
        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    
        return `${formattedHours}:${formattedMinutes}${ampm}`;
    }

    return (
        <div
            className={classNames(
                classes["chat_contacts_list"],
                classes["chat_contacts_list_active"]
            )}
        >
            <div className={classes["chat_search_block"]}>
                <div className={classes["chat_search_input"]}>
                    <div className={classes["image"]}>
                        <Icon id="icon-search" className="icon-search" />
                    </div>
                    <input
                        type="text"
                        placeholder="Search or start a new chat"
                        className={classes["chat_search"]}
                    />
                    <div
                        className={classNames(
                            classes["image"],
                            "display-none",
                            classes["chat_search_close_button"]
                        )}
                    >
                        <Icon id="icon-x" className="icon-x" />
                    </div>
                </div>
            </div>
            <div className={classes["chat_user_lists"]}>
                <div className={classes["chat_chats_block"]}>
                    <h4>Chats </h4>
                    <ul className={classes["chat_list"]}>
                        {chats &&
                            chats.map((chat) => (
                                <li
                                    key={chat.id}
                                    className={classNames(
                                        classes[`chat_status_${chat.chatStatus}`],
                                        "chat-js",
                                        chat.chatId == selectedChat && classes.active
                                    )}
                                    onClick={() => showActiveChat(chat)}
                                >
                                    {chat.photo ? (
                                        <div className={classNames(classes["avatar"], "avatar")}>
                                            <img src={chat.photo} alt="" />
                                        </div>
                                    ) : (
                                        <div className={classNames(classes["avatar"], "avatar")}>
                                            {`${chat.userName.charAt(0)}`}
                                        </div>
                                    )}

                                    <div className={classes["name"]}>
                                        <p>{chat.userName}</p>
                                        <span>{chat.latestMessage.text}</span>
                                    </div>
                                    <div className={classes["chat_info"]}>
                                        <div className={classes["chat_time"]}>{formatDate(chat.latestMessage.date)}</div>
                                        {unreadMessages[chat.chatId] && (
                                            <div
                                                key={chat.chatId}
                                                className={classNames(classes["chat_messages_count"])}
                                            >
                                                {unreadMessages[chat.chatId]}
                                            </div>
                                        )}
                                    </div>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default TextChatList;
